
@import '~@fortawesome/fontawesome-free/css/all.min.css';
 
.MuiDialogActions-root button, .MuiButton-root  {
  font-family: 'Poppins-Medium' !important;
}
    
.react-datepicker__input-container > input {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.2s;
}

.react-datepicker__input-container > input:focus {
  border-color: #3f51b5;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 
@media print {
  .MuiDataGrid-toolbarContainer , 
  .MuiCheckbox-root {
    display: none !important;
  }
  .MuiDataGrid-headerFilterRow {
    display: none !important;
  }
  [data-field="setariDosarMonitorizat"], .setariDosarMonitorizatHead, .setariDosarMonitorizat {display: none !important; width: 0 !important;}
  [data-field="setariDosarSincronizat"], .setariDosarSincronizatHead, .setariDosarSincronizat {display: none !important;  width: 0 !important;}
  [data-field="userDropdown"], .userDropdownHead, .userDropdown {display: none !important;  width: 0 !important;}
  .dosarNou::before {
    content: "" !important; 
  }
}


.fc-event-title {
  text-wrap: wrap;
}
 
 
.fc-event:hover {
  color:#282c34; 
}


.google-button {
  background-color: #db4437 !important;  
  color: #fff !important; 
}

.google-button:hover {
  background-color: #e4301f !important;   
}

.ios-button {
  background-color: #007bff !important;
  color: #fff !important;
}


.ios-button:hover {
  background-color: #0462c6 !important; 
}


.headerTermen {
    background: #eaeaea;
    padding: 20px;
    border-bottom: 1px solid #CCC;
    margin: -16px;
}

.notificari-container {
  position: relative;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.more-events-label {
  font-size: 12px;
  color: #999;
}

.show-more-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.icon {
  width: 16px;
  height: 16px;
  color: #999;
}

.tablescrollBody-300 tbody {
  height: 300px !important;  
  overflow-y: auto;
}

.termenCalendar {
  width: 100%;
  cursor: pointer;
  color: white; 
  padding-left: 10px;
}

.fc-daygrid-day-number button {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(25, 118, 210, 0.04);
  border: 1px solid #1976d2;
  color: #1976d2;
  padding: 3px;
}

.MuiButton-root:hover {
  color: #282c34;
}

.MuiButton-contained:hover {
  color: #eaeaea;
}

.heightModalTab {
  max-height: 300px;
  overflow: auto;
}

.small-dropdown .MuiSelect-select.MuiSelect-select {
  padding: 5px;
  font-size: 14px;
}


.cardApp {
  background-color: #EEE!important;
  border-radius: 5px;
}

.cardApp:hover {
  background-color: #ccc !important; 
}

.gridB  h2, .gridB  a, .gridB  button{
  text-transform: none !important;
  text-decoration: none !important;
}

.linkapp {
  color: #1976d2 !important;
  text-decoration: none;
  margin-left:10px;
  border: 1px solid #1976d2; 
  padding: 4px;
  font-weight: 600;
  font-size:12px;
  text-transform: uppercase;
}

.linkapp:hover {
  color: #000 !important;
  background-color: #eee;
  border: 1px solid #ccc;
}

.fc .fc-popover { 
  z-index: 1000;
}

[role="tabpanel"] {
  width: 100%;
}

.wrap-cell {
  overflow: visible !important;
}

#istoricDosar .MuiDataGrid-viewport,
#istoricDosar .MuiDataGrid-row,
#istoricDosar .MuiDataGrid-renderingZone {
  max-height: fit-content !important;
}

#istoricDosar .MuiDataGrid-cell {
  max-height: fit-content !important;
  overflow: auto;
  white-space: initial !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-bottom: 0 !important;
}
#logActivitate [role="row"] {
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}
#logActivitate .MuiDataGrid-cell { 
  border-bottom: 0 !important;
}

#logActivitate .MuiDataGrid-viewport,
#logActivitate .MuiDataGrid-row,
#logActivitate .MuiDataGrid-renderingZone {
  max-height: fit-content !important;
}

#logActivitate .MuiDataGrid-cell {
  max-height: fit-content !important;
  overflow: auto;
  white-space: initial !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}


.MuiTabs-root .Mui-selected {
  color: #1976d2;
  background: #EAEAEA;
}



@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css');
@import url('https://cdn.datatables.net/1.12.1/css/jquery.dataTables.min.css');
@import url('//code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css');
@import url('https://cdn.datatables.net/1.12.1/css/jquery.dataTables.min.css');

/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
    font-family: Poppins-Regular;
    src: url('fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-SemiBold;
    src: url('fonts/poppins/Poppins-SemiBold.ttf');
}


/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    font-family: Poppins-Regular, sans-serif;
}

body {
    background-color: #f5f5f9 !important;
}


/*---------------------------------------------*/

a {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #fff;
}


/*---------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

p {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
}

ul,
li {
    margin: 0px;
    list-style-type: none;
}


/*---------------------------------------------*/

input {
    outline: none;
    border: none;
}

textarea {
    outline: none;
    border: none;
}

textarea:focus,
input:focus {
    border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus:-moz-placeholder {
    color: transparent;
}

input:focus::-moz-placeholder {
    color: transparent;
}

input:focus:-ms-input-placeholder {
    color: transparent;
}

textarea:focus::-webkit-input-placeholder {
    color: transparent;
}

textarea:focus:-moz-placeholder {
    color: transparent;
}

textarea:focus::-moz-placeholder {
    color: transparent;
}

textarea:focus:-ms-input-placeholder {
    color: transparent;
}

input::-webkit-input-placeholder {
    color: #fff;
}

input:-moz-placeholder {
    color: #fff;
}

input::-moz-placeholder {
    color: #fff;
}

input:-ms-input-placeholder {
    color: #fff;
}

textarea::-webkit-input-placeholder {
    color: #fff;
}

textarea:-moz-placeholder {
    color: #fff;
}

textarea::-moz-placeholder {
    color: #fff;
}

textarea:-ms-input-placeholder {
    color: #fff;
}

label {
    margin: 0;
    display: block;
}


/*---------------------------------------------*/

button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}


/*//////////////////////////////////////////////////////////////////
  [ Utility ]*/

.txt1 {
    font-family: Poppins-Regular;
    font-size: 13px;
    color: #e5e5e5;
    line-height: 1.5;
}


/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 80vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
}

.container-login100::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
}

.wrap-login100 {
    width: 500px;
    border-radius: 10px;
    overflow: hidden;
    padding: 55px 55px 37px 55px;
    background: #4a90e2;
    background: -moz-linear-gradient(top, #4a90e2, #6c63ff);
    background: -webkit-linear-gradient(top, #4a90e2, #6c63ff);
    background: -o-linear-gradient(top, #4a90e2, #6c63ff);
    background: -moz-linear-gradient(top, #4a90e2, #6c63ff);
    background: linear-gradient(to bottom, #4a90e2, #6c63ff);

    opacity: 0.7;
}


/*------------------------------------------------------------------
  [ Form ]*/

.login100-form {
    width: 100%;
}

.login100-form-logo {
    font-size: 60px;
    color: #333333;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 auto;
}

.login100-form-title {
    font-family: Poppins-Medium;
    font-size: 30px;
    color: #fff;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    display: block;
}


/*------------------------------------------------------------------
  [ Input ]*/

.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, 0.24);
    margin-bottom: 30px;
}

.input100 {
    font-family: Poppins-Regular;
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px 0 38px;
}


/*---------------------------------------------*/

.focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 10px;
    left: 0;
    pointer-events: none;
    color:white;
}

.focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 8px;
    left: 0;
    width: 0;
    height: 2px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    background: #fff;
}

.focus-input100::after {
    font-family: Material-Design-Iconic-Font;
    font-size: 22px;
    color: #fff;
    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 6px;
    left: 0px;
    padding-left: 5px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.input100:focus {
    padding-left: 25px;
}

.input100:focus+.focus-input100::after {
    top: -22px;
    font-size: 18px;
}

.input100:focus+.focus-input100::before {
    width: 100%;
}

.has-val.input100+.focus-input100::after {
    top: -22px;
    font-size: 18px;
}

.has-val.input100+.focus-input100::before {
    width: 100%;
}

.has-val.input100 {
    padding-left: 5px;
}


/*==================================================================
  [ Restyle Checkbox ]*/

.contact100-form-checkbox {
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 35px;
}

.input-checkbox100 {
    display: none;
}

.label-checkbox100 {
    font-family: Poppins-Regular;
    font-size: 13px;
    color: #fff;
    line-height: 1.2;
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
}

.label-checkbox100::before {
    content: "\f26b";
    font-family: Material-Design-Iconic-Font;
    font-size: 13px;
    color: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background: #fff;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.input-checkbox100:checked+.label-checkbox100::before {
    color: #555555;
}


/*------------------------------------------------------------------
  [ Button ]*/

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.login100-form-btn {
    font-family: Poppins-Medium;
    font-size: 16px;
    color: #555555;
    line-height: 1.2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 120px;
    height: 50px;
    border-radius: 25px;
    background: #9152f8;
    background: -webkit-linear-gradient(bottom, #4b4d4e, #3c3b49);
    background: -o-linear-gradient(bottom, #4b4d4e, #3c3b49);
    background: -moz-linear-gradient(bottom, #4b4d4e, #3c3b49);
    background: linear-gradient(to top, #4b4d4e, #3c3b49);
 
    position: relative;
    z-index: 1;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-color: #fff;
    top: 0;
    left: 0;
    opacity: 1;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn:hover {
    color: #fff;
}

.login100-form-btn:hover:before {
    opacity: 0;
}


/*------------------------------------------------------------------
  [ Responsive ]*/

@media (max-width: 576px) {
    .wrap-login100 {
        padding: 55px 15px 37px 15px;
    }
}


/*------------------------------------------------------------------
  [ Alert validate ]*/

.validate-input {
    position: relative;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0px;
    pointer-events: none;
    font-family: Poppins-Regular;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.alert-validate::after {
    content: "\f12a";
    font-family: FontAwesome;
    font-size: 16px;
    color: #c80000;
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}

.container-login100 {
    background-image: url('images/bg-01.jpg');
}

#intAvp {
    margin-top: 0 !important;
    padding-top: 0rem !important;
    margin-bottom: 0 !important;
    padding-bottom: 0rem !important;
}

footer .link {
    text-decoration: none;
    color: #6c757d;
}

.logojuristpro {
    width: 100%;
    max-width: 200px;
    ;
}

#margineSus0px {
    margin-top: 0 !important;
}

.cauta1 {
    height: 100%;
    border-radius: 0.375rem 0 0 0.375rem !important;
    -webkit-border-radius: 0.375rem 0 0 0.375rem !important;
    -moz-border-radius: 0.375rem 0 0 0.375rem !important;
    -ms-border-radius: 0.375rem 0 0 0.375rem !important;
    -o-border-radius: 0.375rem 0 0 0.375rem !important;
}

.cauta3 {
    border-radius: 0 0 0 0 !important;
    -webkit-border-radius: 0 0 0 0 !important;
    -moz-border-radius: 0 0 0 0 !important;
    -ms-border-radius: 0 0 0 0 !important;
    -o-border-radius: 0 0 0 0 !important;
}

.cauta4 {
    border-radius: 0 0.375rem 0.375rem 0 !important;
    -webkit-border-radius: 0 0.375rem 0.375rem 0 !important;
    -moz-border-radius: 0 0.375rem 0.375rem 0 !important;
    -ms-border-radius: 0 0.375rem 0.375rem 0 !important;
    -o-border-radius: 0 0.375rem 0.375rem 0 !important;
}


.MuiTablePagination-selectLabel, 
.css-16c50h-MuiInputBase-root-MuiTablePagination-select {display: none !important;}

.MuiDataGrid-main div {
    flex: auto !important;
}
 
  .table-condensed th, .table-condensed td {
    padding: 2px; 
  }
  
 
  .smooth-collapse {
    transition: height 0.5s ease-in-out;
    -webkit-transition: height 0.5s ease-in-out;
    -moz-transition: height 0.5s ease-in-out;
    -ms-transition: height 0.5s ease-in-out;
    -o-transition: height 0.5s ease-in-out;
}
  
  .smooth-collapse > .content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    -webkit-transition: max-height 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out;
    -ms-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
}
  
  .smooth-collapse[open] > .content {
    max-height: 500px;  
  }
  
  

 /* Create a keyframe animation */
@keyframes movingBackground {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  
  /* Apply the animation to your component's container */
  body {
    width: 100%;
    height: 100%;
    background-image: url('images/2.png');
    background-repeat: repeat;
    animation: movingBackground 20s linear infinite;
    -webkit-animation: movingBackground 20s linear infinite;
}

.MuiDataGrid-row {
    cursor: pointer;
  }


  #row-details-dialog, .titluModal { 
    background-image: url('images/2titlu.png');
    background-repeat: repeat;
    animation: movingBackground 20s linear infinite;
    -webkit-animation: movingBackground 20s linear infinite;
    color: #fff;
}

 
.captabel { 
    background-image: url('images/2titlu.png');
    background-repeat: repeat;
    animation: movingBackground 20s linear infinite;
    -webkit-animation: movingBackground 20s linear infinite;
    color: #fff;
    height: 50px;

}

.MuiAccordionSummary-root { 
    background-image: url('images/2titlu.png');
    background-repeat: repeat;
    animation: movingBackground 20s linear infinite;
    -webkit-animation: movingBackground 20s linear infinite;
    color: #fff; 

} 

.customB .MuiAccordionSummary-root { 
    background-image: url('images/2titluModal.png');
    background-repeat: repeat;
    animation: movingBackground 20s linear infinite;
    -webkit-animation: movingBackground 20s linear infinite;
    color: #333333; 
    min-height: 25px;;

} 
.customB .MuiAccordionSummary-root p {  
    color: #333333;  
    padding: 1px;
}

.MuiAccordionSummary-root p {  
    color: #fff;  
}
.MuiCollapse-vertical {background-color: #fafafa;}

.Mui-disabled {
    cursor: not-allowed !important;
  }

  #vertical-tabpanel-1, #vertical-tabpanel-2, #vertical-tabpanel-3, #vertical-tabpanel-4, #vertical-tabpanel-5, #vertical-tabpanel-6, #vertical-tabpanel-7 {width: 100%;}

  .MuiDataGrid-virtualScroller {min-height: 100px;}

 

.hidden {display: none !important;}


.selectGrupuri {padding-left: 10px !important; padding-right: 10px !important;}

.titluModal { 
    background-image: url('images/2titlu.png');
    background-repeat: repeat;
    animation: movingBackground 20s linear infinite;
    -webkit-animation: movingBackground 20s linear infinite;
    color: #fff;
}

.list-item:hover {
    background-color: #f5f5f5; 
}
.striped {
    background-color: #f9f9f9;
}

.textarea-style {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;  
}

 

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    background-color: #fff; 
    border-radius: 3px;
    padding: 2px 8px;  
    border: 1px solid #e5e5e5;
    margin-top:5px;
    width: 100%;
  }

  .upload-btn-wrapper:hover { 
    background-color: #F1f1f1;  
  }
  
  .upload-btn-wrapper .btnr {
    border: 1px solid gray;
    color: gray;
    background-color: #eee;
    padding: 5px 10px;
    border-radius: 1px;
    font-size: 14px;
    font-weight: bold;
    position: relative;
  }
  .upload-btn-wrapper .btnr + span {
    padding: 5px;
    font-weight: normal;
    }
  
  .upload-btn-wrapper input[type=file] {
      font-size: 42px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
  }

  .upload-btn-wrapper .btnr:hover {
    background-color: #b6b3b3;
    border: 0px;
    border-right: 1px solid #e5e5e5;
  }

  .upload-btn-wrapper .btnr  {
    background-color: #fff;
    color: #000;
    border: 0px;
    border-right: 1px solid #e5e5e5;
    padding: 10px 15px;
    margin-right: 20px;
    transition: .5s;
  }

  input[type=date]:first-child {
    cursor: pointer;
  }

  .headerActe {
    background-color: #b224ef;
    padding:5px;
    color: #FFFFFF;
    font-weight: 500;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    background-image: url('images/2titlu.png');
    background-repeat: repeat;
    animation: movingBackground 20s linear infinite;
    -webkit-animation: movingBackground 20s linear infinite;
    padding-left: 10px;
}

.boxActe {
    padding: 10px !important;
}
.boxActe:hover {
    background-color: #F2F1F2;
}

button.MuiTab-root:hover { 
    background-color: #ddd1f2;
    color: #000000;
}

.dateFirma table tbody tr:hover {
    background-color: #f5f5f5;
} 
.dateFirma tbody tr:nth-of-type(odd) {
    background-color: #e9e9e9;
}
.dateFirma table tbody tr td {
    padding: 5px;
} 

@media print {
    .no-print {
      display: none !important;
    }
  }

  .checkbox-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  
    grid-gap: 10px; 
  }

  .switch-label-off {
    color: #a4a4a4;  
  }
 .drepturi .MuiFormControlLabel-label {
    font-size: 15px !important;
  }



  #roluriUtilizatori table tr:hover {
    background-color: #f5f5f5;
} 
#roluriUtilizatori tbody tr:nth-of-type(even) {
    background-color: #e9e9e9;
}
#roluriUtilizatori  td {
    padding: 6px !important;
    padding-left: 20px !important;
} 

#roluriUtilizatori thead {
    background-color: #6e6e6edb;
}

#roluriUtilizatori thead th {
    color: #FFFFFF;
    font-weight: bold;
}

.tabelInfoDosar {
    background-color: #FFFFFF;
    border: 1px solid #CCC;
    width: 100%;
}
.tabelInfoDosar tr:hover {
    background-color: #f5f5f5;
} 

.tabelInfoDosar tbody tr:nth-of-type(even) {
    background-color: #e9e9e9;
}

.tabelInfoDosar td {
    padding: 3px !important;
    padding-left: 20px !important;
}
.tabelInfoDosar th {
    padding: 3px !important;
    padding-left: 20px !important;
} 


.tabelStari table tr:hover, .tabelUseriAdmin table tr:hover, #tabelDosareGrup table tr:hover {
    background-color: #f5f5f5;
} 
.tabelStari tbody tr:nth-of-type(even), .tabelUseriAdmin tbody tr:nth-of-type(even), #tabelDosareGrup tbody tr:nth-of-type(even) {
    background-color: #e9e9e9;
}
.tabelStari td, .tabelUseriAdmin td, #tabelDosareGrup  td {
    padding: 6px !important;
    padding-left: 20px !important;
}
.tabelStari th, .tabelUseriAdmin th,#tabelDosareGrup  th {
    padding: 6px !important;
    padding-left: 20px !important;
} 

.tabelUseriAdmin table, #tabelDosareGrup table  {
    width: 100% !important;
    font-size:14px !important;
}

#tabelGrupUtilizatori table tr:hover {
    background-color: #f5f5f5;
} 
#tabelGrupUtilizatori tbody tr:nth-of-type(even) {
    background-color: #e9e9e9;
}
#tabelGrupUtilizatori  td {
    padding: 1px !important;
    padding-left: 20px !important;
}
#tabelGrupUtilizatori  th {
    padding: 6px !important;
    padding-left: 20px !important;
} 

#tabelGrupUtilizatori table  {
    width: 100% !important;
    font-size:14px !important;
}
.drepturiUser .MuiFormControlLabel-label {
    font-size: 14px !important;
}

.none {
    display: none;
  }

  span.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root.MuiRadio-root.MuiRadio-colorPrimary.MuiRadio-root.MuiRadio-colorPrimary.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    padding: 2px;
  }

  
  .drepturiAdmin {
    display: grid;
    grid-template-columns: repeat(2, 1fr);  
    grid-gap: 10px; 
  }

  .userSelectat:hover, .dataApi:hover, .dosarAsociat:hover {
    background-color: #d87eff !important;
    color: #ffffff !important;
    }

    .userSelectat:hover p, .dataApi:hover p, .dosarAsociat:hover p {
        color: #ffffff !important;
    } 

    .userSelectat, .dataApi, .dosarAsociat {
        background-color: #ffffff;
    } 
    
    .userSelectat {
        color: #c1c1c1 !important;
    }

    .dataApi {
        color: #252525 !important;
    }

    .dosarAsociat {
        color: #252525 !important;
        cursor: pointer; 
    }

    .dataApim .dosarAsociat {
        border: 1px solid #e6e0e0;
        margin: 3px !important;
        padding: 3px;
    }
    
    .dataCurentaSelectata {color: #FFFFFF !important;}
    .dosarAsociatSelectat {color: #FFFFFF !important;}


    .dosare-counter {
        position: absolute;
        top: 2;
        right: 0;
        background-color: #9c27b0;
        color: white;
        border-radius: 50%;
        padding: 3px 7px 2px 7px;
        font-size: 11px;
        font-weight: bold;
        transform: translate(50%, -50%);
        -webkit-transform: translate(50%, -50%);
        -moz-transform: translate(50%, -50%);
        -ms-transform: translate(50%, -50%);
        -o-transform: translate(50%, -50%);
}

 
.MuiTooltip-tooltip { 
    font-size: 12px !important; 
    color: white !important; 
  }
 
 
.meniu li a { 
    border-radius: 20px;  
    font-size: 14px;
}

.meniu li a.active {
    background: #EEE;
    border-radius: 20px; 
    color: #000000; 
    padding: 5px;
    margin: 2px;
    zoom: 101%;
}

.meniu li .nav-link:hover {
    background: #EEE !important;
    border-radius: 20px !important;
    color: #000000; 
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    -ms-border-radius: 20px !important;
    -o-border-radius: 20px !important;
    zoom: 101%;
}


.butoaneModalaInfo  {
    background-color: #FAFAFA;
    display: block;
    width: 100%;
    color: #CCC;
    padding:1px;
    border-radius: 5px;
    border:1px solid #CCC;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding-left: 10px;
}
 
.butoaneModalaInfo button {
    padding:2px;
    font-size: 10.7px !important;
    margin-left: 3px;
}

.butoaneModalaInfo button:hover {
    background-color: #d7dde0;
}

.butoaneModalaInfo .MuiButton-textPrimary {
    color: #282c34;
}


.detaliiBuletin {
    background-color: #FAFAFA;
    display: block;
    width: 100%;
    color: #333;
    padding:8px;
    border-radius: 5px;
    border:1px solid #CCC;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding-left: 10px;
    font-size: 14px;
}
.detaliiBuletin button {
    background-color: #424446;
    font-size: 11px;
    color: #FAFAFA;
    float: right;
    padding: 4px;
    margin-top: -3px;
}

.detaliiBuletintext {
    font-size: 14px;
    padding-left: 5px;
}

.dateClient {
    background-color: #6c757d;
    width: 100%;
}

.separator {
    background-color: #AC8BDF;
    color: white;
    padding: 5px !important;
    margin: 0 !important;
    margin-top:10px !important;
    margin-left: 20px !important;
    margin-bottom: -5px !important;
}

.butoaneTipDosar .Mui-selected  {
    background: rgb(243, 132, 14) !important;
    color: white !important;
}

.butoaneTipDosar button  {
    background: rgba(244, 244, 244, 0.211);
    color: rgb(39, 36, 36);
}

.comprimare .MuiGrid-item {
    padding-top:3px !important;
}

textarea {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: none repeat scroll 0 0 rgba(142, 142, 142, 0.219);
    border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
    border-image: none;
    border-radius: 6px 6px 6px 6px;
    border-style: none solid solid none;
    border-width: medium 1px 1px medium;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
    color: #555555;  
    padding: 5px 8px;
    transition: background-color 0.2s ease 0s;
  }
  
  
  textarea:focus {
      background: none repeat scroll 0 0 #f9f4f9;
      outline-width: 0;
      border: 1px solid #CCC;
  }

  .row-container-inst {
    display: flex;
    align-items: center;
  }
  
  .row-container-inst .col-field {
    flex-grow: 1;
    margin-right: 8px;
  }
  
  .row-container-inst .col-field:last-child {
    margin-right: 0;
  }
  
  .row-container-inst span {
    padding: 0 8px;
  }
  
  /* Stil pentru câmpuri obligatorii necompletate */
  .row-container-inst input:required:invalid {
    border-color: red;
  }

  .inputmic {
    height: 24px !important;
  }
  .inputmic .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 20px !important;
    padding: 2px 10px 2px;
  }


  @keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotating {
    animation: rotate 2s linear infinite;
}
.pvtRows, .pvtVals {
    width: 150px !important; 
  }

  .pvtHorizList, .pvtRows, .pvtVals, .pvtRowLabel, .pvtAxisLabel, .pvtColLabel, .pvtTotalLabel, .pvtTotalLabel, thead th {
    background-color: #fafafa !important;
    border-color: #e0e0e0 !important;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important; 
    border-left-style: solid !important;
    border-left-width: 1px !important;
  }

  .pvtUnused {
    background-color: #fafafa !important;
    border-color: #e0e0e0 !important;
    border-bottom-style: dotted !important;
    border-bottom-width: 1px !important; 
    border-left-style: dotted !important;
    border-left-width: 1px !important;
  }


.pivot-table-container .pvtUi {
    display: flex;
    flex-direction: column;
  }
 
 
  /* Stil pentru celula td care conține elementele */
  #trUnused { 
    margin-bottom: 12px;
    background-color: #fafafa !important;
    border-color: #e0e0e0 !important;
    border-bottom-style: dotted !important;
    border-bottom-width: 1px !important; 
    border-left-style: dotted !important;
    border-left-width: 1px !important;
  }
  #trUnused  td, #trUnused .pvtRenderers { 
    border-color: #e0e0e0 !important;
    border-bottom-style: dotted !important;
    border-bottom-width: 1px !important; 
    border-left-style: dotted !important;
    border-left-width: 1px !important;
  }

.pvtUi td.pvtUnused {
    width: 100%;
    display: flex;
    flex-wrap: wrap; 
    justify-content: flex-start;  
    align-items: center;  
    gap: 0px;  
}


.pvtHorizList { 
    width: 100% !important;
    overflow: auto;
}

.pvtOutput { 
    overflow: auto;
}

#dateTabel {
 
    
}

#headerRaport {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}
#functiiTabel {
    align-self: flex-end;
    justify-content: flex-end;
}
#dateTabel {
    align-self: flex-start;
    justify-content: flex-start;
}

#trUnused {
    display: flex;
    justify-content: center; 
    display: inline-table;
}
#trUnused td {
    vertical-align: middle;
    justify-content: center;
}


.pvtAttr {
    -ms-flex-align: center !important;
    align-items: center !important;
    background: #6c757d !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    color: #343a40 !important;
    cursor: default !important;
    display: -ms-flexbox !important;
    display: flex !important;
    font-weight: normal !important;
    height: 24px !important;
    padding: 0 8px !important;
    text-transform: none !important;
    vertical-align: bottom !important;
    z-index: 1 !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    -ms-border-radius: 4px !important;
    -o-border-radius: 4px !important;
    color: #fff !important;
    font-size: 13px;
    justify-content: space-between;
}
.pvtTriangle {color: #FFFFFF !important}
.pvtAttr:hover{
    background: #5c636a !important;
    border-color: #5c636a !important;
}
  @media print {
    .inapoiRapoarte, .pvtVals,  #footer, #menu, #trUnused, #functiiTabel, .pvtVertList, .pvtRenderers {
        visibility: hidden;
      }
      .inapoiRapoarte, .pvtVals, #footer, #menu, #trUnused, #functiiTabel, .pvtVertList, .pvtRenderers  {
        display: none;
      }
    .pivot-table-container, .pivot-table-container * {
      visibility: visible;
    }
    .pivot-table-container {
      
      left: 0;
      top: 0;
    }
    .card {
        border: 0 !important;
    }
    .dataRapoarte {
        margin: 0 !important;
        padding: 0 !important;
    }
 
  }
  

.alertaConexare .MuiAlert-icon {
    font-size: 16px; padding-left: 10px; margin:0; padding-top: 4px;
}
.alertaConexare .MuiAlert-message {
    padding-left: 10px; margin:0; padding-top: 4px; padding: 4px 4px;
}

.for-pdf-export {
    zoom: 0.76;
  }


  @keyframes pulse {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  .pulsating-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
  }
  
  .pulsating-dots span {
    animation: pulse 1s infinite ease-in-out;
    animation-delay: 0s;
    padding: 0 4px;
    font-size: 54px;
    color: #b224ef;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
  
  .pulsating-dots span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .pulsating-dots span:nth-child(3) {
    animation-delay: 0.4s;
  }


  .notificari-container {
    z-index: 3;
  }
  
  .notificari-aplicatie {
    border-radius: 10px;
    display: table;
    position: relative;
    width: 100%;
    max-width: 800px;
    margin-left: -300px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    position: absolute;
    background: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border: 1px solid #CCC;
  }
  
  .taburi-notificari {
    list-style-type: none; 
    margin: 0;  
    padding: 0;  
    overflow: hidden;  
    border-bottom: 1px solid #ccc;  
    background-color: #f1f1f1;  
    width: 100%;
    min-width: 430px;
    padding:5px;
  }
  
  .taburi-notificari li {
    float: left;
    margin-left:2px;
    margin-right:2px;
    padding: 0 5px;
    cursor: pointer;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    border: 1px solid #ccc;
    display: flex;
}
  
  .taburi-notificari li a {
    display: block;  
    color: black;  
    text-align: center;  
    padding: 14px 16px;  
    text-decoration: none;  
    transition: 0.3s;  
  }
  
  .taburi-notificari li:hover {
    background-color: #c06be4;
    color: #fff;
  }
  
  .taburi-notificari .activ {
    background-color: #b224ef !important;  
    border-bottom: none; 
    color: #fff;
  }
  
  .continut-notificari {
    padding: 10px;
    max-height: 300px;
    height: 100%;
    overflow: auto;
  }
  
  .notificare.necitita { 
    cursor: pointer;
    padding:5px;
    font-weight: bold;
  }
  .notificare.necitita:hover {
    background-color: #F2F3F5;
  }
  
  .badge {
    background-color: red;
    color: white;
    padding: 2px 6px;
    border-radius: 50%;
    font-size: 10px !important;
    position: absolute;
    top: -10px;
    right: -10px;
    min-width: 20px;
    min-height: 20px;
  }

  .nr {
    background-color: #fff;
    color: #b224ef;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    min-width: 20px !important;
    min-height: 20px !important;
    width: 100% !important;
    height: 100% !important;
    max-width: 20px !important;
    max-height: 20px !important;
    display: unset;
    text-align: center;
    padding: 1px;
}

.notificare {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .notificare-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .notificare-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #1877F2;
  }
  
  .elementExport .MuiFormControlLabel-label {
    font-size: 14px;
  }
  .elementExport  {
    padding-top: 1px !important;
  }



  .FR {
    background-color: #428BCA !important;
    color: #fafafa !important;
    box-shadow: 0 1px 0 rgba(12, 13, 14, 0.2), 0 0 0 1px #FFF inset !important;
    width: 45px !important; 
    text-align: center;
}

.FC {
    background-color: #45B6AF !important;
    color: #fafafa !important;
    box-shadow: 0 1px 0 rgba(12, 13, 14, 0.2), 0 0 0 1px #FFF inset !important;
    width: 45px !important; 
    text-align: center;
} 
.FB {
    background-color: #DFBA49 !important;
    color: #fafafa !important;
    box-shadow: 0 1px 0 rgba(12, 13, 14, 0.2), 0 0 0 1px #FFF inset !important;
    width: 45px !important; 
    text-align: center;
}

kbd {
    color: #fff;
    background-color: #333;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25);
    padding: 0 !important; 
    -webkit-box-shadow: none;
    box-shadow: none;
    text-align: center;
    font-size: 13px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
kbd.dataVi {
    background-color: #dee2e6;
    color: #333;
    padding: 0 5px !important; 
}

.fullWidthDatePickerWrapper,
.fullWidthDatePicker {
    width: 100%;
}

.fullWidthDatePicker {
    height: 40px; 
    padding: 0.5rem; 
}

.elementCuCopii {
    flex-basis: 32.333333% !important;
    margin:5px !important
}
.ELM_ExportSingur .MuiCheckbox-root, .elementNepot .MuiCheckbox-root, .ELM_Copil  .MuiCheckbox-root {
    padding:1px;
}

.elementCuCopiiNepoti, .elementCuCopii   {
    border: 1px solid #CCC;
}
.ELM_CuCopiiNepoti  {
    width:100%;
    background-color: #e9e9e9ec; 
    margin-left: -4px !important;
}

.elementCuCopiiNepoti  .ELM_Copil {
    width:100%;
    background-color: #f5f5f5ec;
    margin-top: 10px;
    margin-left: -4px !important;
    padding-left: 10px;
}
.elementNepot  {
    margin-left: 4px !important;
}
.ELM_Nepot   {
    margin-left: 18px !important;
}
.elementCuCopii .ELM_CuCopii  {
    width:100%;
    background-color: #e9e9e9ec;
    margin-left: -4px !important;
}
.elementCopil .ELM_Copil {
    margin-left: 18px !important;
}

.elementCuCopiiNepoti .elementCopil .ELM_Copil {
    margin-left: -4px !important;
}

.elementeExport .MuiFormControlLabel-label {
    font-size: 14px;
}

.elementeExport {
    margin-top:10px !important;
    padding-left: 10px;
    padding-right: 10px;
}

.elementCuCopiiNepoti {
    margin-top:20px !important; 
    margin-bottom: 10px !important;
}
.elementExportSingur {
    padding: 0 !important;
    margin: 0 !important;
}

.elementNepot, .elementCuCopii  .elementCopil  {
    margin: 0 !important;
}

.modalImprimare .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root, .modalExport .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    margin-top:-7px;
}

.elementeCuloareApp {
    display: flex;
}

.elementeCuloareApp .culoare {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    margin: 5px; 
    text-align: center;
    cursor: pointer;
}
.elementeCuloareApp .culoare:hover { 
    border: 2px solid #999; 
    zoom: 105%;
}
.definireCuloareApp {padding: 10px;}
.definireCuloareApp span {font-size: 13px;}

.MuiDialogActions-root {
    background-color: #F3F3F3 !important;
}

.anDosareAsociate {
    background-color: #EFEFEF;
    padding-left: 10px;
    font-weight: bold;
}

.maxWidth {
    width: 100% !important;
}


.inputMicR .MuiInputBase-input {
  padding: 12px !important;
}
.AutocomRap input {
 height: 30px;
}
.AutocomRap label[data-shrink=false] {
  margin-top: 3px !important;
}
.inputMic label {
  margin-top: -4px;
}

.inputMicR label[data-shrink=false] {
  margin-top: -4px !important;
}
.inputMic .MuiInputBase-input {
    padding: 9px !important;
}
.inputMic label {
    margin-top: -7px;
}
.inputMicMic .MuiInputBase-input {
    padding: 1px !important;
    font-size: 15px;
}
.inputMicMic label {
    margin-top: -15px;
}
.inputMicMic .MuiInputLabel-shrink  {
    margin-top: -1px;
}

.inputMicMic2 .MuiInputBase-input {
  padding: 5px !important;
}
.inputMicMic2 label {
  margin-top: -9px;
}
.inputMicMic2 .MuiInputLabel-shrink  {
  margin-top: -1px;
}
.MuiDataGrid-panelWrapper .inputMicMic2  {
 margin-top: 10px;
}
.exista-db {
    background-color: #f0d0c0; 
}
.exista {
    color: #c80000 !important;
}

.headerTabel {
    background: #6f6f6f !important;
}

.headerLista {
    color: #000000;
    background: #efefef !important;
    text-transform: uppercase !important;
}


.headerLista:hover {
    color: #000000;
    background: #9a9a9a !important;
}
.textCentrat a {
text-align: center;
}

.row-inactive {
  background-color: #ffd9d9 !important;   
}
.headerDoc {
  border: 1px solid #CCC;
  background-color: #EFEFEF;
  padding: 5px; 
  font-weight: 500;
}
.itemUpload {
  border: 1px solid #ccc;
  padding: 3px !important;
  margin-bottom: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.itemUpload div span {
  font-size: 0.9rem;
}
.itemUpload {
  transition: background-color 0.3s linear, transform 0.3s linear;
  background-color: #ffffff;  
  display: inline-block;  
  padding: 10px;  
  border-radius: 5px; 
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);  
  cursor: pointer;  
  width: 100%;
}

.itemUpload:hover {
  background-color: #f0f0f0; 
  transform: scale(1.05); 
}

.react-tabs__tab-list {
  transition: background-color 0.3s linear, transform 0.3s linear;
  background-color: #ffffff;  
  display: inline-block;  
  padding: 10px;   
  box-shadow: 0 2px 0 rgba(0,0,0,0.1);  
  width: 100%;
}

.cardApp, .parteCont {
  transition: background-color 0.3s linear, transform 0.3s linear;
  background-color: #ffffff;  
  display: inline-block;  
  padding: 10px;  
  border-radius: 5px; 
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);  
  cursor: pointer;  
  width: 100%;
}

.cardApp:hover {
  background-color: #f0f0f0; 
  transform: scale(1.05); 
  color: #282c34 !important;
  font-weight: bold !important;
}
.parteCont:hover {
  background-color: #fffefe;
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
}
.IconCardApp {
  float: right;
  font-size: 2.2rem !important;
}

.cardApp {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.vechime {
  padding: 5px !important;
  border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  -o-border-radius: 3px !important;
}

.fc-event div {
  text-shadow: -1px 0 #9d9a9a, 0 1px #393939, 1px 0 #6a6a6a, 0 -1px #939393 !important;
} 

.colDrepturi {
  border: 1px solid #ccc;
  padding: 10px 20px 10px 20px;
  margin: 10px;
}
.titluColDrepturi {
  background-color: #d6d6d6;
  width:100%;
  padding: 5px;
  font-weight: bold !important;
  color: #333;
}

.denumireUser {
  padding:3px;
  padding-left: 10px;
  font-weight: bold;
  border-bottom: 1px solid #CCC;
}

.meniuDropdown a {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}
.denumireStadiu {
  background-color: #e4eaec;
  border: 1px solid #ccc;
  padding-left: 10px;
}

.fc-list-event:has(.userEveniment) .fc-list-event-time,
.fc-list-event:has(.dosarEveniment) .fc-list-event-time {
  visibility: hidden;
  content: "";
}

.dreaptaManual h1 {
  font-size: 24px;
  margin-top:30px; 
}
.dreaptaManual ul {
  font-size: 14px; 
}
.stangaManual p:hover a {
  color: #333; 
  font-weight: bold;
}
.stangaManual p  a:focus {
  color: #333; 
  font-weight: bold;
  background-color: #eee;
}
.stangaManual p:hover {
  background-color: #eee;
} 
 .toateManual {
  color: #333;
 }
 .labelLuni label{z-index: auto;}

 .dosarImp {
  transition: background-color 0.3s linear, transform 0.3s linear;
  background-color: #ffffff;  
  display: inline-block;  
  padding: 10px;  
  border-radius: 5px; 
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);  
  cursor: pointer;  
  width: 100%;
}
.dosarImp:hover {
  background-color: #fffefe;
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
}

#listaDosare .MuiDataGrid-headerFilterRow .MuiDataGrid-cell:focus, #listaDosare .MuiDataGrid-columnHeader:focus, #listaDosare .MuiDataGrid-columnHeader:focus-within, #listaDosare .MuiDataGrid-cell:focus-within {
  outline: solid #1976d2 0px !important;
  outline: none !important;
}

.campCustomFiltrare label {line-height: 16px;}

#listaDosare .MuiDataGrid-headerFilterRow {height: 62px;}

.MuiDataGrid-filterForm .campCustomFiltrare {margin-top:8px !important;}

.titluModal button svg[data-testid=CloseIcon] {
  color: #FFF !important;
}
.modalHelp button svg[data-testid=CloseIcon] {
  color: rgba(0, 0, 0, 0.26)!important;
}

.titluModal [data-testid=HelpOutlineIcon] {
  color: #FFF !important;
}

.tabelRaport .container {
  display: flex;
  flex-direction: column;
  height: 100%;  
}
.tabelRaport .MuiDataGrid-row--dynamicHeight {
  min-height: 35px !important;
  height: auto;
}

.FAQ .MuiAccordionSummary-gutters {
  height: 20px;
}
.FAQ .MuiAccordionSummary-root {
  background: linear-gradient(90deg, #e0e0e0, #f5f5f5);  
  transition: background 0.3s ease;  
}
.FAQ .Mui-expanded .MuiAccordionSummary-root {
  background: linear-gradient(90deg, #c5c5c5, #d6d6d6); 
  cursor: pointer;  
}

.FAQ .MuiAccordionSummary-root:hover {
  background: linear-gradient(90deg, #d6d6d6, #e0e0e0); 
  cursor: pointer;  
}

.FAQ .MuiAccordionSummary-root:active {
  background: linear-gradient(90deg, #c5c5c5, #d6d6d6);  
}

.FAQ h6 {
  margin: 0;
  font-weight: 500;
  font-size: 0.95rem;
  line-height: 1.2;
  color: #1d1b1b;
}

.FAQ {
  max-width: 100% !important;
}
.MuiDialogContent-root a:hover {
  color: #1d1b1b;
  font-weight: 400;
}
.meniuActive {
  background-color: #e0e0e0 !important; 
  font-weight: bold !important;  
}

#listaDosare .inputMicMic  .MuiInputBase-adornedEnd.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-root.MuiOutlinedInput-root.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  width: 130px;
}

.chipModern {
  justify-content: center !important;
  height: 22px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  user-select: none !important;
  margin: 3px !important;
  max-width: calc(100% - 6px) !important;
}
.instanteCautare label[data-shrink=false] {
  top:0px !important;
}
.instanteCautare label[data-shrink=true] {
  top:3px !important; 
}
.obiecteCautare label[data-shrink=false] {
  top:0px !important;
}
.obiecteCautare label[data-shrink=true] {
  top:3px !important; 
}
 
 
.fc-event div:hover {
  background-color: rgb(87, 87, 87) !important;
}

.MuiButton-textSizeSmall {
  font-size: 0.85rem !important;
}

#listaDosare .MuiDataGrid-columnHeadersInner {
  padding-top: 10px; 
}
.cautareOnline .MuiDataGrid-columnHeadersInner {
  padding-top: 1px !important; 
}

.userDropdownHead .MuiAutocomplete-inputRoot {
  padding: 3px !important;
}

.userDropdown .MuiAutocomplete-inputRoot { 
  padding: 3px !important;
}
.userDropdownHead {
  margin-top: 10px;
}

.document-category-header {
 background-color: #d6d6d6;
 padding:5px;
 border: 1px solid #ccc;
}

.listaDocumente li:hover {
  background-color: #EAEAEA;
}

.listaDocumente ul {
  background-color: #fafafa;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 10px;
}

.MuiDialog-container .titluComponenta {
  display: none !important;
}

.titluComponentaOK {
  display: none;
}
.MuiDialog-container .titluComponentaOK {
  display: block !important;
}
.MuiDialog-container .container {
  border: 0 !important;
}

.divswal {
  text-align: left; 
  display: flex;
}
.divswal .switch-container {
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.divswal .switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.divswal .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.divswal .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.divswal .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.divswal input:checked + .slider {
  background-color: #2196F3;
}

.divswal input:checked + .slider:before {
  transform: translateX(14px);
}

.linkLeg {
  color: #1d1b1b !important;
  cursor: pointer;
 }
 .linkLeg:hover {
  font-weight: bold;
 }

 .coloanaCelulaScroll div {
  max-height: 25px !important;
 }
 .coloanaCelulaScroll { 
  overflow-y: auto !important;
 }

 .hr {
  border-bottom: 1px solid #CCC !important;
  width: 100% !important;
 }

 .pvtUnused .remove-btn {
  display: none;
 }

 .remove-btn {
  border-radius: 80%;
  background-color: #FAFAFA;
  padding-right:4px;
  padding-left:4px;
  font-size: 10px;
  margin-left: 5px;
 }

 .remove-btn:hover { 
  background-color: #EEEEEE; 
  font-weight: bold;
 }

 .MuiDataGrid-root--densityCompact {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 4px 0px;
  background: #fbfbfb;
 }

 .custom-icon-button {
  border-radius: 4px !important;  
  width: 40px;  
  height: 40px;  
  background-color: #ffffff !important;  
  color: #4c4c4c;  
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease; 
}

.custom-icon-button:hover {
  background-color: #d0d0d0 !important; 
}

.notita {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px !important;
  color: #181818 !important;
}
.notita-success {
  background-color: #F3F2F1 !important;
}
.notita-primary {
  background-color: #E2F1FF !important;
}
.notita-warning {
  background-color: #FFF7D1 !important;
}
.notita-error {
  background-color: #FFE4F1 !important;
}